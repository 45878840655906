import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { ComMain } from '@components/index'
import PdfLink from '../assets/images/map/map.pdf'

const MapPage = () => {

  return (
    <main className='mainMap'>
      {/* CONTENTS */}

      <ComMain
        ttl="Access Map"
        subttl="現地案内図"
        caption=""
      >
      </ComMain>

      <section className='sec-map'>
        <div className="c-content">

          <div className="map-wrap">
            <div className="map-box">
              <div className="map">
                <StaticImage
                  src="../assets/images/map/map.jpg"
                  alt=""
                />
              </div>
            </div>

            <div className="link-box">
              <a href={PdfLink} className="link" target="_blank" rel="noopener noreferrer">
                <span>印刷する</span>
              </a>
              <a href="https://goo.gl/maps/4vNT1ho6codWaXsF6" className="link" target="_blank" rel="noopener noreferrer">
              <span>Google Map</span>
              </a>
            </div>
          </div>

        </div>
      </section>

      {/* CONTENTS */}
    </main>
  )
}
export default MapPage